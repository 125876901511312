import { Pagination } from '@/@types/tool'

export interface MessageInfo {
  busiId: number
  busiType: string
  createTime: string
  createUser: number
  deptId: number
  id: number
  notes: string
  receiveRole: number
  receiveUser: number
  remindDesc: string
  remindId: number
  remindName: string
  remindType: string
  roleCompany: number
  status: string
  updateTime: string
  userId: number
  viewFlag: string
  remindParam: string
  messageTemplateId: string
}

interface MessageInfoParams {
  current?: number
  size?: number
  descs?: string
  ascs?: string
  remindType?: string
  viewFlag?: string
  receiveUser?: number
}

// 获取分页站内信息
export const fetchMessageCenterInfoAPI = async (
  page: Pagination & {
    descs?: string
    ascs?: string
    remindType?: string
    viewFlag?: string
    receiveUser?: number
  }
): Promise<{ records: MessageInfo[]; pagination: Pagination }> => {
  const params: MessageInfoParams = {
    current: page.current,
    remindType: page.remindType,
    viewFlag: page.viewFlag,
    receiveUser: page.receiveUser,
    size: page.pageSize,
  }
  if (!page.descs && !page.ascs) {
    params.descs = 'create_time'
  }
  page.descs && (params.descs = page.descs)
  page.ascs && (params.ascs = page.ascs)
  const [res, err] = await $axios.fn.get('/osprey/basics/bsmessageremind/page', { params })

  if (err) {
    return {
      records: [],
      pagination: {},
    }
  } else {
    return {
      records: res?.records,
      pagination: {
        current: page.current,
        pageSize: page.pageSize,
        total: res?.total,
      },
    }
  }
}

interface messageParams {
  ids?: Array<number>
  id?: number
  viewFlag?: string
  remindId?: number
  remindType?: string
  receiveUser?: number
}

/*
批量修改站内信息已读状态
 */
export const editMessagereMindAPI = async (ids?: Array<number>) => {
  const [, err] = await $axios.fn.put('/osprey/basics/bsmessageremind/updateBatch', ids)
  return !err
}

/*
批量删除站内信息已读状态
 */
export const delMessagereMindAPI = async (ids) => {
  const [, err] = await $axios.fn.delete('/osprey/basics/bsmessageremind/delBatch', {
    data: ids,
  })
  return !err
}

/*
查询站内未读信息条数
 */
export const fetchMessageCountAPI = async (params: messageParams) => {
  const [res, err] = await $axios.fn.get<MessageInfo[]>('/osprey/basics/bsmessageremind/count', {
    params: {
      receiveUser: params.receiveUser,
      remindType: params.remindType,
      viewFlag: params.viewFlag,
    },
  })
  return err ? [] : res || []
}
