const baseMixin = {
  name: 'activatable',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    labels() {
      return [].concat(this.labelArr)
    },
    activatorProps() {
      return {
        active: this.active,
        open: this.open,
        close: this.close,
      }
    },
  },
  methods: {
    open() {
      if (!this.disabled) {
        this.active = true
      }
    },
    close() {
      this.active = false
    },
  },
}

export default baseMixin
