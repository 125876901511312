//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vuex from 'vuex'
import SwitchRole from './switch-role'
import Activator from '@/mixins/activatable'
import { canCheckRoleList } from '@/api/login'

export default {
  name: 'SwitchRoleModal',
  components: { SwitchRole },
  mixins: [Activator],
  data() {
    return {
      confirmLoading: false,
    }
  },
  computed: {
    ...Vuex.mapState(['userInfo']),
    ...Vuex.mapGetters(['roleList', 'currentRole']),
    scopeProps() {
      return {
        ...this.activatorProps,
        switchRole: this.switchRole,
      }
    },
    canCheckRoles() {
      return canCheckRoleList(this.roleList)
    },
  },
  methods: {
    ...Vuex.mapActions(['switchUserRole']),
    switchRole() {
      this.$nextTick(() => {
        this.open()
      })
    },
    async refreshRole(role) {
      await this.switchUserRole(role.roleId)
      window.location.replace('/')
    },
  },
}
