import { loginByAccount, TokenPayload, refreshToken, loginOut, switchUserRole } from '@/api/login'
import { getStore, removeStore, setStore, StoragePlacement } from '@/util/store'
import { AUTH_INFO_STORE_KEY, USER_INFO_STORE_KEY } from '@/const/project'
import { fetchUserInfoDetail } from '@/api/admin/user'
import { deepClone } from '@/util/util'
import { Role } from '@/@types/admin/role'
import { fetchMessageCountAPI } from '@/api/message-center'
import { basementTypes } from '@/const/common-type'

export enum SysRole {
  ADMIN = 'ROLE_ADMIN',
  FRANCHISEE = 'ROLE_FRANCHISEE',
  MANAGER = 'ROLE_MANAGER',
  R_AND_D = 'ROLE_R_AND_D',
}

export const state = () => ({
  userInfo: getStore(USER_INFO_STORE_KEY) || null,
  authInfo: getStore(AUTH_INFO_STORE_KEY) || null,
  notifyCount: 0,
})

export const getters = {
  roleList: (state): Role[] => {
    return state.userInfo.roleList || []
  },
  hasRole:
    (state, getters) =>
    (roleCode: SysRole | SysRole[]): boolean => {
      if (!Array.isArray(roleCode)) {
        roleCode = [roleCode]
      }
      return roleCode.some((roleCode) => {
        return [getters.currentRole]
          .concat(
            getters.roleList.filter(
              (role) => role.checkFlag === '0' && role.parentId === getters.currentRole.roleId
            )
          )
          .some((role) => role.roleCode === roleCode)
      })
    },
  isAdmin: (state, getters) => {
    return getters.hasRole([SysRole.ADMIN, SysRole.MANAGER, SysRole.R_AND_D])
  },
  currentRole: (state): Role => {
    if (state.authInfo.user_info.roleId) {
      const exist = state.userInfo.roleList.find(
        (role) => role.roleId === state.authInfo.user_info.roleId
      )
      if (exist && exist.checkFlag === '1') return exist
    }
    for (const auth of state.authInfo.user_info.authorities || []) {
      const match = auth.authority.match(/ROLE_(.+)/)
      if (match && match[1]) {
        const exist = state.userInfo.roleList.find((role) => role.roleId === match[1])
        if (exist && exist.checkFlag === '1') return exist
      }
    }
    return state.userInfo.roleList.find((role) => role.checkFlag === '1')
  },
  isCanApproved: (state) => (approveUserId: number) => {
    const { id: userId, parentId } = state.authInfo.user_info
    if (parentId === 1 || !parentId) {
      return userId === approveUserId
    } else {
      return parentId === approveUserId
    }
  },
  sceneTypeList(state, getters) {
    const roleLists = state.userInfo.roleList
      .map((v) => v.roleId)
      .filter((f) => f === 12 || f === 13)
      .map((d) => {
        return {
          label: basementTypes[d],
          value: d === 12 ? '1' : '2',
        }
      })
    return getters.isAdmin
      ? [
          { label: '小区', value: '1' },
          { label: '商业', value: '2' },
        ]
      : roleLists
  },
  isOil() {
    return process.env.NODE_ENV === 'development'
      ? window.location.href.includes('localhost')
      : window.location.href.includes('rongyaomedia')
  },
}

export const mutations = {
  setAuthInfo(state, { payload, rememberMe }) {
    state.authInfo = { ...payload, rememberMe }
    const storage: StoragePlacement = rememberMe ? 'localStorage' : 'sessionStorage'
    setStore(AUTH_INFO_STORE_KEY, state.authInfo, storage)
    $axios.setToken(payload.access_token, payload.token_type)
  },
  setUserInfo(state, userInfo) {
    state.userInfo = deepClone(userInfo)
    setStore(USER_INFO_STORE_KEY, state.userInfo, 'localStorage')
  },
  setNotifyCount(state, count) {
    state.notifyCount = count
  },
}

export const actions = {
  async fetchUserInfo({ state, commit }) {
    const userId = state.authInfo.user_info.id
    const userInfo = await fetchUserInfoDetail(userId)
    if (userInfo) {
      commit('setUserInfo', userInfo)
    }
    return userInfo
  },
  clearAuthInfo() {
    removeStore(USER_INFO_STORE_KEY, 'localStorage')
    removeStore(AUTH_INFO_STORE_KEY, 'localStorage')
    removeStore(USER_INFO_STORE_KEY, 'sessionStorage')
    removeStore(AUTH_INFO_STORE_KEY, 'sessionStorage')
  },
  async loginByPassword({ commit, dispatch }, { username, password, rememberMe }) {
    dispatch('clearAuthInfo')
    const res = await loginByAccount(username, password)
    if (res.code === 0) {
      const payload = res.data
      if (payload && payload.user_info) {
        commit('setAuthInfo', { payload, rememberMe })
      }
    }
    return res
  },
  async refreshToken({ commit, dispatch }) {
    let payload: TokenPayload = getStore(AUTH_INFO_STORE_KEY)
    // eslint-disable-next-line camelcase
    const { refresh_token, rememberMe } = payload
    payload = await refreshToken(refresh_token)
    if (payload) {
      commit('setAuthInfo', { payload, rememberMe })
    }
    return payload
  },
  async switchUserRole({ commit, dispatch, state }, roleId) {
    try {
      const payload = await switchUserRole(state.authInfo.access_token, roleId)
      const rememberMe = state.authInfo.rememberMe
      if (payload && payload.user_info) {
        commit('setAuthInfo', { payload, rememberMe })
      }
      return payload
    } catch (e) {
      return null
    }
  },
  async loginOut({ commit, state, getters }) {
    if (!getters.isAdmin) {
      await loginOut()
    }
    const rememberMe = state.authInfo.rememberMe
    const storage: StoragePlacement = rememberMe ? 'localStorage' : 'sessionStorage'
    removeStore(USER_INFO_STORE_KEY, storage)
    removeStore(AUTH_INFO_STORE_KEY, storage)
  },
  async freshNotifyCount({ commit, state }) {
    const count = await fetchMessageCountAPI({
      receiveUser: state.userInfo.userId,
      viewFlag: '0',
    })
    commit('setNotifyCount', count)
    return count
  },
}
