import Vue from 'vue'

if (!Vue.__fetch_mixin__) {
  Vue.__fetch_mixin__ = true
  Vue.mixin({
    data() {
      return {
        mounted: false,
      }
    },
    fetch() {
      if (typeof this.fetch === 'function') {
        return this.fetch()
      }
    },
    mounted() {
      this.mounted = true
    },
  })
}
