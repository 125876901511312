//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Vuex from 'vuex'
import Spacer from '@/components/spacer.vue'
import { fetchUserMenuTree } from '@/api/admin/menu'
import SwitchRoleModal from '@/views/switch-role-modal/index.vue'
import { canCheckRoleList } from '@/api/login'

export default {
  name: 'DefaultLayout',
  components: { Spacer, SwitchRoleModal },
  data() {
    return {
      locale: zhCN,
      hoveredGroup: null,
      hoveredGroupRect: {},
      breadcrumbLinks: [],
      menuList: [],
      loaded: false,
      prevVersion: null,
      selectedKeys: [],
      openKeys: [],
    }
  },
  computed: {
    ...Vuex.mapState(['userInfo', 'notifyCount']),
    ...Vuex.mapGetters(['currentRole', 'roleList', 'isOil']),
    canSwitchRole() {
      return canCheckRoleList(this.roleList).length > 1
    },
    hasChildren() {
      return (children) => {
        return Array.isArray(children) && children.length > 0
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.freshNotifyCount()
        this.setSelectKeys()
        this.setOpenKeys(this.menuList)
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV === 'production' && /^diku\./.test(window.location.hostname)) {
      this.prevVersion = require('@/static/version.json')
    }
    this.setSelectKeys()
    // this.prevVersion = require('@/static/version.json')
  },
  methods: {
    setSelectKeys() {
      this.selectedKeys = [
        this.replacePath(
          this.$route.meta.parentPath ? this.$route.meta.parentPath : this.$route.path
        ),
      ]
    },
    replacePath(path) {
      return path?.replace?.(/(\/|\\)/g, '')
    },
    getOpenKeys(menuList) {
      for (let i = 0; i < menuList.length; i++) {
        if (
          this.replacePath(menuList[i].path) ===
          (this.replacePath(this.$route.meta.parentPath) || this.replacePath(this.$route.path))
        ) {
          return [true, i]
        } else {
          const res = this.getOpenKeys(menuList[i].children, false)
          if (res !== undefined) return [true, i]
        }
      }
    },
    setOpenKeys(menuList) {
      const [_, i] = this.getOpenKeys(menuList, true) || [true, 0]
      if (menuList[i].path) {
        this.openKeys = [this.replacePath(menuList[i].path)]
      } else {
        this.openKeys = [i]
      }
    },
    selectItem({ item }) {
      if (this.$route.path === item.value) return
      this.selectedKeys = [item.value]
      this.$router.push({
        path: item.value,
        params: {},
        query: {},
      })
    },
    docBody() {
      return window.document.body
    },
    ...Vuex.mapActions(['freshNotifyCount']),
    async fetch() {
      const userInfo = await this.fetchUserInfo()
      if (!userInfo) {
        this.$router.replace({
          name: 'login',
        })
      } else {
        this.menuList = await fetchUserMenuTree()
        if (this.menuList.length <= 0) {
          this.$router.replace({
            name: 'login',
          })
          this.$message.warn('你无权访问！')
        } else {
          this.setOpenKeys(this.menuList)
        }
      }
      this.loaded = true
      this.freshNotifyCount().then(() => {
        const interval = setInterval(() => {
          if (this && !this._isDestroyed) {
            this.freshNotifyCount()
          } else {
            window.clearInterval(interval)
          }
        }, 60000)
      })
    },
    ...Vuex.mapActions(['loginOut', 'fetchUserInfo']),
    logout() {
      this.$confirm({
        title: '是否退出系统？',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          const res = await this.loginOut()
          this.$router.replace({ name: 'login' })
        },
        icon: () => {},
      })
    },
    navUserCenter() {
      this.$router.replace({
        name: 'account-profile',
      })
    },
    openChange(keys) {
      this.openKeys = keys.filter((k) => !this.openKeys.includes(k))
    },
  },
}
