import Vue from 'vue'

Vue.mixin({
  methods: {
    $getDefaultContainer() {
      const container = window.document.getElementsByClassName('container__content')?.[0]
      if (container) {
        return container
      } else {
        return window.document.body
      }
    },
  },
})
