import Vue from 'vue'
import { message } from 'ant-design-vue'
import SingleFlight from '@15bil/single-flight'
import { getStore, removeStore } from '@/util/store'
import { AUTH_INFO_STORE_KEY, USER_INFO_STORE_KEY } from '@/const/project'
import { deepClone } from '@/util/util'

const validOptionField = (val) => typeof val === 'undefined' || val === true

const autoWarningStatusCodes = (code) => {
  return (code >= 300 && code < 500) || code === 200
}

export default (ctx) => {
  if (!Vue.__auth__) {
    Vue.__auth__ = true
    const { $axios, store, redirect } = ctx
    const authPayload = getStore(AUTH_INFO_STORE_KEY)
    if (authPayload && authPayload.access_token) {
      $axios.setToken(authPayload.access_token, authPayload.token_type)
    }
    $axios.onRequest((config) => {
      if (!validOptionField(config.withAuth)) {
        if (!config.withAuth) {
          config.headers.Authorization = ''
        } else {
          config.headers.Authorization = config.withAuth
        }
      }
      return config
    })
    $axios.onResponse((response) => {
      if (response.data?.code === 1) {
        return Promise.reject(response)
      }
      return response
    })
    $axios.onError(async (err) => {
      if (err.config) {
        if (err.status === 401 && err.config.url.match(/^[\\/]*osprey/)) {
          const authPayload = getStore(AUTH_INFO_STORE_KEY)
          if (validOptionField(err.config.freshToken) && authPayload && authPayload.refresh_token) {
            const payload = await new Promise((resolve) => {
              SingleFlight.do(
                'lock:tokenRefresh',
                (done) => {
                  store
                    .dispatch('refreshToken', authPayload.refresh_token)
                    .then((res) => done(null, res))
                    .catch(done)
                },
                (_, res) => {
                  resolve(res)
                }
              )
            })
            if (payload) {
              const config = deepClone(err.config || {})
              Object.assign(config, {
                optimize: false,
                freshToken: false,
                'axios-retry': false,
              })
              delete config.headers.Authorization
              return $axios.request(config)
            }
          }
          removeStore(AUTH_INFO_STORE_KEY)
          removeStore(USER_INFO_STORE_KEY)
          setTimeout(() => {
            redirect({ name: 'login' })
          }, 0)
          SingleFlight.do(
            'lock:authFailMsg',
            (done) => {
              message.error('身份信息异常，请重新登陆')
              setTimeout(done, 3000)
            },
            () => {
              // handle 401 end
            }
          )
        } else if (validOptionField(err.config.autoWarning) && autoWarningStatusCodes(err.status)) {
          // 弱密码msg不显示
          if (!(err.status === 426 && err.data.msg === 'invalid_credentials_strength')) {
            message.error(
              err.data.msg || err.data.message || err.msg || err.message || err.statusText
            )
          }
        } else if (err.status > 500) {
          SingleFlight.do(
            'lock:serverErrorMsg',
            (done) => {
              message.error('服务异常，请稍后重试！')
              setTimeout(done, 3000)
            },
            () => {
              // handle 401 end
            }
          )
        }
      }
      return err
    })
  }
}
