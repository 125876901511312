import axiosOptimize from '@15bil/axios-optimize'
import Vue from 'vue'

export default ({ $axios }) => {
  if (!Vue.__axios_optimize__) {
    Vue.__axios_optimize__ = true
    $axios.setBaseURL(window.location.origin)
    axiosOptimize($axios, {
      isFailed(res) {
        return res.status !== 200 || res?.data?.code === 1
      },
      errorFormat(res) {
        return new Error(res?.data?.message || res?.data?.msg || res?.statusText)
      },
    })
    if (window) {
      window.$axios = $axios
    }
  }
}
