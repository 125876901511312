export enum MenuRouteKeepAlive {
  DISABLED = '0',
  ENABLED = '1',
}

export enum MenuRouteType {
  MENU = '0',
  BUTTON = '1',
}

export interface MenuItem {
  id: number
  menuId: number
  parentId?: number
  icon?: string
  name?: string
  spread: boolean
  path: string
  keepAlive: MenuRouteKeepAlive
  permission?: string
  type: MenuRouteType
  label?: string
  sort: number
  hasChildren: boolean
  children?: MenuItem[]
  systemId: number
}
