import { Pagination } from '@/@types/tool'
import { UserAccessChannel, UserInfo, UserLockFlag } from '@/@types/admin/user'

export const fetchUserList = async (
  pagination: Pagination,
  keyword?: string
): Promise<{ records: UserInfo[]; pagination: Pagination }> => {
  const [res, err] = await $axios.fn.get('/osprey/admin/user/page', {
    params: {
      current: pagination.current,
      size: pagination.pageSize,
      username: keyword,
    },
  })
  if (err) {
    return {
      records: [],
      pagination,
    }
  } else {
    return {
      records: res?.records || [],
      pagination: {
        ...pagination,
        total: res?.total,
      },
    }
  }
}

export interface EditUserDetailParams extends Partial<UserInfo> {
  role?: number[]
  deptId?: number
  newpassword1?: any
  newusername?: number
  username?: string
  userId?: number
  lockFlag?: UserLockFlag
}

export const updateUserDetail = async (params: EditUserDetailParams) => {
  const [, err] = await $axios.fn.put('/osprey/admin/user/edit', params)
  return !err
}
export const updateUserContent = async (params: EditUserDetailParams) => {
  const [, err] = await $axios.fn.put('/osprey/admin/user', params)
  return !err
}
export interface CreateUserAccountParams extends Partial<UserInfo> {
  username: string
  password: string
  accessChannel: UserAccessChannel
  parentId: string
}

export const UserAccessChannels = {
  [UserAccessChannel.MIDI_DUCK]: '弥迭鸭',
  [UserAccessChannel.OSPREY_APP]: '百川App',
  [UserAccessChannel.ENGINEER]: '工程管理',
}

export const createUserAccount = async (params: CreateUserAccountParams) => {
  const [, err] = await $axios.fn.post('/osprey/admin/user/create', params)
  return !err
}

export const fetchUserInfoDetail = async (id: string | number) => {
  const [res, err] = await $axios.fn.get(`/osprey/admin/user/${id}`, {
    retry: {
      retries: 5,
    },
  })
  return err ? null : res
}

export const fetchSysUserList = async (sysId?: number) => {
  const [res, err] = sysId
    ? await $axios.fn.get('/osprey/admin/system/user/list', {
        params: {
          systemId: sysId,
        },
      })
    : await $axios.fn.get('/osprey/admin/system/user/list-all')
  return err ? [] : res || []
}

export const relateSystemAndUser = async (relations: { userId: number; systemId: number }[]) => {
  const [, err] = await $axios.fn.post('/osprey/admin/system/user/save', relations)
  return !err
}

export const fetchSysUserRoleRelate = async (systemId: number, userId: number) => {
  const [res, err] = await $axios.fn.get('/osprey/admin/system/user/role/list', {
    params: {
      systemId,
      userId,
    },
  })
  const result = err ? [] : res || []
  return result.map((r) => r.roleId)
}

export const saveSysUserRoleRelate = async (
  params: {
    systemId: number
    userId: number
    roleId: number
  }[]
) => {
  const [, err] = await $axios.fn.post('/osprey/admin/system/user/role/save', params)
  return !err
}
