import { getStore } from '@/util/store'
import { TokenPayload } from '@/api/login'
import { AUTH_INFO_STORE_KEY } from '@/const/project'

const NoAuthPages = ['login', 'map']

export default (ctx) => {
  const { redirect, route } = ctx
  const payload = getStore<TokenPayload | null>(AUTH_INFO_STORE_KEY)
  const authed = !!(payload && payload.access_token)
  if (!authed && !NoAuthPages.includes(route.name)) {
    redirect({ name: 'login' })
  }
}
