import Vue from 'vue'

import { AdvOrderStatusList, AdvOrderTypes, basementTypes } from '@/const/common-type'

const filters = {
  launchTypeFilter(val) {
    return AdvOrderTypes[parseInt(val)]
  },
  statusFilter(val) {
    return AdvOrderStatusList[val]
  },
  sceneFilter(val) {
    return basementTypes[val]
  },
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
