import qs from 'qs'
import { encryption } from '@/util/util'
import { AUTH_KEY } from '@/const/project'
import { Role, RoleCheckFlag } from '@/@types/admin/role'

export interface UserInfo {
  id: number
  username: string
  phone: string
  avatar: string
  authorities: Array<any>
  roleId: number
}

export interface TokenPayload {
  // eslint-disable-next-line camelcase
  access_token: string
  // eslint-disable-next-line camelcase
  expires_in: number
  // eslint-disable-next-line camelcase
  refresh_token: string
  scope: string
  // eslint-disable-next-line camelcase
  token_type: string
  // eslint-disable-next-line camelcase
  user_info: UserInfo

  rememberMe?: boolean
}

export interface LoginPayLoad {
  code: number
  data?: TokenPayload
  msg?: string
}

export interface UpdatePasswordParams {
  newpassword1?: any
  newusername?: number
  username?: string
  userId?: number
}

const BASIC_AUTH = 'Basic cG9zc3k6cG9zc3k='

export const loginByAccount = async (username, password): Promise<LoginPayLoad> => {
  const user = encryption({
    data: {
      username,
      password,
    },
    key: AUTH_KEY,
    param: ['password'],
  })
  try {
    const res = await $axios.request({
      url: '/osprey/auth/oauth/token',
      headers: {
        isToken: false,
        'content-type': 'application/x-www-form-urlencoded',
      },
      withAuth: BASIC_AUTH,
      method: 'post',
      params: {
        grant_type: 'password',
      },
      data: qs.stringify({
        username: user.username,
        password: user.password,
      }),
    })
    if (res.status !== 200) {
      return {
        code: 1,
        msg: res?.data?.msg || 'unknown error',
      }
    }
    const payload: TokenPayload = res.data
    if (!payload.user_info.roleId) {
      // eslint-disable-next-line camelcase
      const firstRole = payload.user_info?.authorities?.[0]?.authority
      if (firstRole) {
        // eslint-disable-next-line camelcase
        payload.user_info.roleId = parseInt(firstRole.replace('ROLE_', ''))
      }
    }
    return {
      code: 0,
      data: payload,
    }
  } catch (e) {
    return {
      code: 1,
      msg: e.message || 'unknown error',
    }
  }
}

export const refreshToken = async (refreshToken: string): Promise<TokenPayload | undefined> => {
  try {
    const res = await $axios.request({
      url: '/osprey/auth/oauth/token',
      withAuth: BASIC_AUTH,
      headers: {
        isToken: false,
      },
      method: 'post',
      params: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      },
      optimize: false,
      autoWarning: false,
    })
    return res.data as TokenPayload
  } catch (e) {
    return undefined
  }
}

export const loginOut = async (): Promise<Boolean> => {
  const [res, err] = await $axios.fn.delete('/osprey/auth/token/logout')
  if (err) {
    return false
  } else {
    return true
  }
}

export const canCheckRoleList = (roleList: Role[]): Role[] => {
  return roleList
    .filter((a) => a.checkFlag === RoleCheckFlag.CAN_CHECK)
    .sort((a, b) => a.roleId - b.roleId)
}

export const fetchUserSwitchRoleList = async (username: string) => {
  const [res, err] = await $axios.fn.get(`/osprey/admin/user/info/list/role/${username}`, {
    withAuth: BASIC_AUTH,
  })
  return err ? [] : canCheckRoleList(res || [])
}

export const switchUserRole = async (token: string, roleId: number) => {
  const [res, err] = await $axios.fn.put(`/osprey/auth/token/switch/${token}`, undefined, {
    withAuth: BASIC_AUTH,
    headers: {
      SystemUserRole: roleId,
    },
  })
  return err ? null : res
}

export const updateUserPwd = async (params: UpdatePasswordParams) => {
  const [, err] = await $axios.fn.put('/osprey/admin/user/update-pwd', params)
  return !err
}
