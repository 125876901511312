export enum RoleCheckFlag {
  CAN_CHECK = '1',
  FORBIDDEN = '0',
}

export interface Role {
  roleId: number
  roleName: string
  roleCode: string
  roleDesc: string
  createTime: string
  updateTime: string
  checkFlag: RoleCheckFlag
}
