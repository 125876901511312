import Vue from 'vue'
// Safari下Date构造函数无法解析 'YYYY-MM-DD HH:mm:ss' 格式时间
// （但是可以解析 'YYYY-MM-DD'，很怪）
// 必须把格式改成 'YYYY/MM/DD HH:mm:ss'

if (!Vue.__safari_folyfill__) {
  Vue.__safari_folyfill__ = true
  if (/[sS]afari/.test(navigator.userAgent) && !/[cC]hrome/.test(navigator.userAgent)) {
    window.Date = (function (Date) {
      function MyDate() {
        if (arguments.length === 1) {
          const arg = arguments[0]
          if (typeof arg === 'string' && !arg.includes('T')) {
            arguments[0] = arg.replace(/-/g, '/')
          }
        }
        const bind = Function.bind
        const unbind = bind.bind(bind)
        return new (unbind(Date, null).apply(null, arguments))()
      }

      // @see https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Date#%E6%96%B9%E6%B3%95
      // 有三个静态方法
      for (const k of ['now', 'parse', 'UTC']) {
        if (Object.hasOwnProperty.call(Date, k) && typeof Date[k] === 'function') {
          MyDate[k] = Date[k]
        }
      }

      MyDate.prototype = Date.prototype
      return MyDate
    })(Date)
  }
}
