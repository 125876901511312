import Vue from 'vue'
// import Antd from 'ant-design-vue/lib'
import {
  Button,
  ConfigProvider,
  Modal,
  FormModel,
  Input,
  Checkbox,
  Tooltip,
  Spin,
  Icon,
  Menu,
  Row,
  Col,
  DatePicker,
  Select,
  Dropdown,
  Avatar,
  Table,
  Skeleton,
  Carousel,
  Tabs,
  Descriptions,
  Popconfirm,
  Divider,
  Form,
  InputNumber,
  Upload,
  message,
  Empty,
  Alert,
  Tag,
} from 'ant-design-vue'

Vue.use(Button)
  .use(ConfigProvider)
  .use(Modal)
  .use(FormModel)
  .use(Input)
  .use(Checkbox)
  .use(Tooltip)
  .use(Spin)
  .use(Icon)
  .use(Menu)
  .use(Row)
  .use(Col)
  .use(DatePicker)
  .use(Select)
  .use(Dropdown)
  .use(Avatar)
  .use(Table)
  .use(Skeleton)
  .use(Carousel)
  .use(Tabs)
  .use(Descriptions)
  .use(Popconfirm)
  .use(Divider)
  .use(Form)
  .use(InputNumber)
  .use(Upload)
  .use(Empty)
  .use(Alert)
  .use(Tag)

// 全局挂载
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
