//
//
//
//
//
//
//

export default {
  layout: 'full',
  props: {
    error: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    is404() {
      return this.error.statusCode === 404
    },
    message() {
      return this.is404 ? '页面不存在' : this.error.message
    },
  },
  methods: {
    refresh() {
      window.location.reload()
    },
  },
}
