import { MenuItem, MenuRouteKeepAlive, MenuRouteType } from '@/@types/admin/menu'

export const fetchMenuList = async (parentId?: string) => {
  const [res, err] = await $axios.fn.get<MenuItem[]>('/osprey/admin/menu', {
    params: {
      parentId,
    },
    retry: {
      retries: 5,
    },
  })
  const records = res || []
  const sorted = (arr: MenuItem[]) => {
    return arr
      .sort((a, b) => a.sort - b.sort)
      .map((item) => {
        item.children = sorted(item.children)
        return item
      })
  }
  return err ? [] : sorted(records)
}

export const fetchMenuTree = async (lazy?: boolean, parentId?: string) => {
  const [res, err] = await $axios.fn.get<MenuItem[]>('/osprey/admin/menu/tree', {
    params: {
      lazy,
      parentId,
    },
  })
  return err ? [] : res || []
}

export interface CreateMenuItemParams {
  name: string
  path: string
  icon: string
  permission: string
  type: MenuRouteType
  keepAlive: MenuRouteKeepAlive
  sort: number
  parentId?: number
}

export const createMenuItem = async (params: Partial<CreateMenuItemParams>) => {
  const [_, err] = await $axios.fn.post('/osprey/admin/menu', params)
  return !err
}

export const fetchMenuItem = async (id: string) => {
  const [res, err] = await $axios.fn.get<MenuItem>(`/osprey/admin/menu/${id}`)
  return err ? undefined : res
}

export const deleteMenuItem = async (id: string) => {
  const [_, err] = await $axios.fn.delete(`/osprey/admin/menu/${id}`)
  return !err
}

export const updateMenuItem = async (params: MenuItem) => {
  const [_, err] = await $axios.fn.put('/osprey/admin/menu', params)
  return !err
}

export const MenuRouteTypes = {
  [MenuRouteType.MENU]: '菜单权限',
  [MenuRouteType.BUTTON]: '操作权限',
}

export const fetchRoleMenus = async (roleId: number) => {
  const [res, err] = await $axios.fn.get(`/osprey/admin/menu/tree/${roleId}`)
  return err ? [] : res || []
}

export const toRoleMenuTree = (list: MenuItem[]): MenuItem[] => {
  const tree = []
  list.forEach((menu, i) => {
    if (menu.parentId === -1) {
      tree.push(menu)
    } else {
      const prt = list.find((m) => m.menuId === menu.parentId)
      if (!Array.isArray(prt.children)) {
        prt.children = []
      }
      prt.children.push(menu)
    }
  })
  list.forEach((menu) => {
    menu.children = (menu.children || []).sort((a, b) => a.sort - b.sort)
  })
  return tree.sort((a, b) => a.sort - b.sort)
}

export const fetchSysMenuList = async (sys: number) => {
  const [res, err] = await $axios.fn.get('/osprey/admin/system/menu/list', {
    params: {
      systemId: sys,
    },
    retry: {
      retries: 5,
    },
  })
  return err ? [] : res || []
}

export const saveSysMenu = async (params: Partial<MenuItem>) => {
  const [, err] = await $axios.fn.post('/osprey/admin/system/menu/save', params)
  return !err
}

export const fetchSysRoleMenu = async (params: { roleId: number; systemId: number }) => {
  const [res, err] = await $axios.fn.get('/osprey/admin/system/role/menu/list', {
    params,
  })
  return err ? [] : res || []
}

export const fetchUserMenuTree = async () => {
  const [res, err] = await $axios.fn.get('/osprey/admin/system/menu/user')
  return err ? [] : res || []
}
