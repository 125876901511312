import Vue from 'vue'

export default ({ store }, inject) => {
  Vue.filter('hasRole', (roleCode) => {
    return store.getters.hasRole(roleCode)
  })
  inject('hasRole', (roleCode) => {
    return store.getters.hasRole(roleCode)
  })
  inject('isAdmin', () => {
    return store.getters.isAdmin
  })
}
