//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import less from 'less'
export default {
  name: 'Dashboard',
  data() {
    return {
      locale: zhCN,
    }
  },
  computed: {
    activeTab() {
      return this.$route.name
    },
    menuList() {
      return [
        // {
        //   label: '首页',
        //   name: 'index-dashboard',
        //   tag: 'index',
        // },
        // {
        //   label: '点位管理',
        //   name: 'dashboard-point',
        //   tag: 'point',
        // },
      ]
    },
  },
  methods: {
    isActiveGroup(group) {
      const isActiveRoute = (to = {}) => {
        const routeName = to?.name
        if (!routeName) return false
        if (routeName.includes(group.tag)) return true
        return false
      }
      if (isActiveRoute(this.$route)) return true
      return false
    },
  },
}
