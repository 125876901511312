import * as CryptoJS from 'crypto-js'

export const prefixZero = (num, n) => {
  return (Array(n).join('0') + num).slice(-n)
}
/**
 *加密处理
 */
export const encryption = (params) => {
  const { data, type, param } = params
  let { key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach((ele) => {
      const data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      const iv = key
      // 加密
      const encrypted = CryptoJS.AES.encrypt(data, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

export const getObjType = (obj) => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = <T = any>(data: T): T => {
  const type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    // @ts-ignore
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  const o1 = obj1 instanceof Object
  const o2 = obj2 instanceof Object
  /*  判断不是对象  */
  if (!o1 || !o2) {
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (const attr in obj1) {
    const t1 = obj1[attr] instanceof Object
    const t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}

/**
 * 时间转换
 */
export const transferTime = (time) => {
  if (time) {
    const date = new Date(time)
    const y = date.getFullYear()
    let m: string | number = date.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d: string | number = date.getDate()
    d = d < 10 ? '0' + d : d
    let h: string | number = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute: string | number = date.getMinutes()
    const second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    return y + '/' + m + '/' + d + ' ' + h + ':' + minute
  }
}

/**
 * 钱转换格式
 */
export const NumToMoney = (num = 0, type = 1, className = 'value1', unit = '元') => {
  // type 1分 2元
  const n = type === 1 ? Number(num / 100) : Number(num)
  let text
  if (Math.abs(n) > 9999.99) {
    text = `<span class="${className}">${(n / 10000).toFixed(2)}</span>万${unit}`
  } else {
    text = `<span class="${className}">${n.toFixed(2)}</span>${unit}`
  }
  // console.info(num,text)
  return text
}
export const NumToMoney1 = (num = 0, type = 1, space = false) => {
  // type 1分 2元
  const n = type === 1 ? Number(num / 100) : Number(num)
  const text = `${n.toFixed(2)}${space ? ' ' : ''}元`
  return text
}
export const NumToMoney2 = (num = 0, type = 1, space = false) => {
  // type 1分 2元
  const n = type === 1 ? Number(num / 1000) : Number(num)
  const text = `${n.toFixed(2)}${space ? ' ' : ''}元`
  return text
}
export const SecondsToHour = (num = 0) => {
  // 秒转换小时
  const text = `${(Number(num) / 60 / 60).toFixed(2)}小时`
  return text
}

/**
 * 转换人民币格式
 */
export const rmb = (num) => {
  return '￥ ' + Number(num).toFixed(2)
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}

/**
 * 字节长度
 */
export const getCharLength = (str) => {
  let len = 0
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      len += 2
    } else {
      len++
    }
  }
  return len
}

/**
 * 获取字符的字节长度
 * @param str
 * @returns 字符长度
 */
export function getStrlen(str: string): number {
  // eslint-disable-next-line
  return str.replace(/[^\x00-\xff]/g, '**').length
}

/**
 * dataUrl转blob
 * @function dataURLtoBlob
 * @params {string} dataUrl
 * @returns {Blob}
 */
export const dataURLtoBlob = (dataUrl: string): Blob => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const dataURLtoFile = (
  dataUrl: string,
  fileName: string,
  options?: FilePropertyBag
): File => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, options)
}

export const pointsCenter = (points: [number | string, number | string][]): [number, number] => {
  let minLat = null
  let maxLat = null
  let minLng = null
  let maxLng = null

  points.forEach((point) => {
    const lng = parseFloat(point[0] as string)
    const lat = parseFloat(point[1] as string)
    if (lat < minLat || minLat === null) {
      minLat = lat
    }
    if (lat > maxLat || maxLat === null) {
      maxLat = lat
    }
    if (lng < minLng || minLng === null) {
      minLng = lng
    }
    if (lng > maxLng || maxLng === null) {
      maxLng = lng
    }
  })

  const lng = minLng + (maxLng - minLng) / 2
  const lat = minLat + (maxLat - minLat) / 2

  return [lng, lat]
}

export const hasOwnProperty = (obj, property: string) => {
  return Object.prototype.hasOwnProperty.call(obj, property)
}
