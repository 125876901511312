import { PROJECT_NAME } from '@/const/project'

export type StoragePlacement = 'sessionStorage' | 'localStorage'

export interface StoreStruct<T = any> {
  dataType: string
  content: T
  type: StoragePlacement
  time: number
}

const validateNull = (val) => {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '')
      return true
    return false
  }
  return false
}

const prefixName = (name: string) => {
  return `${PROJECT_NAME}:${name}`
}

export const setStore = <T = any>(
  name: string,
  content: T,
  type: StoragePlacement = 'localStorage'
) => {
  name = prefixName(name)
  const result: StoreStruct<T> = {
    dataType: typeof content,
    content,
    type,
    time: new Date().getTime(),
  }
  if (type === 'sessionStorage') {
    sessionStorage.setItem(name, JSON.stringify(result))
  } else {
    localStorage.setItem(name, JSON.stringify(result))
  }
}

export const getStore = <T = any>(name: string, debug: boolean = false): T => {
  name = prefixName(name)
  let result = {} as StoreStruct
  let content
  let obj = sessionStorage.getItem(name)
  if (validateNull(obj)) {
    obj = localStorage.getItem(name)
  }
  if (validateNull(obj)) {
    return undefined
  }
  try {
    result = JSON.parse(obj)
  } catch {
    // @ts-ignore
    return obj
  }
  if (debug) {
    // @ts-ignore
    return obj
  }
  if (result.dataType === 'string') {
    content = result.content
  } else if (result.dataType === 'number') {
    content = Number(result.content)
  } else if (result.dataType === 'boolean') {
    content = Boolean(result.content)
  } else if (result.dataType === 'object') {
    content = result.content
  }
  return content
}

export const removeStore = (name: string, type: StoragePlacement = 'localStorage') => {
  name = prefixName(name)
  if (type === 'sessionStorage') {
    sessionStorage.removeItem(name)
  } else {
    localStorage.removeItem(name)
  }
}

export const clearStore = (type: StoragePlacement = 'localStorage') => {
  if (type === 'sessionStorage') {
    sessionStorage.clear()
  } else {
    localStorage.clear()
  }
}
