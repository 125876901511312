export enum AdvOrderStatus {
  PENDING = '1',
  PREPARING = '2',
  DELAY = '3',
  COMPLETE = '4',
}

export const AdvOrderStatusList = {
  [AdvOrderStatus.PENDING]: '等待中',
  [AdvOrderStatus.PREPARING]: '投放中',
  [AdvOrderStatus.DELAY]: '延播中',
  [AdvOrderStatus.COMPLETE]: '已结束',
}

export enum AdvOrderType {
  DISPERSED_QTY = 1,
  TIMING_QTY,
  VEHICLE_TAG,
  VEHICLE_PLATE,
}

export const AdvOrderTypes = {
  [AdvOrderType.DISPERSED_QTY]: '分散保量轮播',
  [AdvOrderType.TIMING_QTY]: '定点定时轮播',
  [AdvOrderType.VEHICLE_TAG]: '精准标签触播',
  [AdvOrderType.VEHICLE_PLATE]: '指定车牌触播',
}

export enum ApproveStatusType {
  PENDING = '1',
  RESOLVED = '2',
  REJECTED = '3',
}

export const ApproveStatusTypes = {
  [ApproveStatusType.PENDING]: '审批中',
  [ApproveStatusType.RESOLVED]: '已通过',
  [ApproveStatusType.REJECTED]: '已拒绝',
}

export enum basementEnv {
  DK = '1',
  BIZ = '2',
}
export const basementEnvs = [
  {
    value: basementEnv.DK,
    label: '小区',
  },
  {
    value: basementEnv.BIZ,
    label: '商业',
  },
]
export const basementTypes = {
  [basementEnv.DK]: '小区',
  [basementEnv.BIZ]: '商业',
}

export enum AdvOrderApproveStatus {
  APPROVING = '1',
  PASS = '2',
  REJECT = '3',
}

export const AdvOrderApproveStatusList = {
  [AdvOrderApproveStatus.APPROVING]: '审核中',
  [AdvOrderApproveStatus.PASS]: '已通过',
  [AdvOrderApproveStatus.REJECT]: '拒绝',
}
