import { render, staticRenderFns } from "./switch-role.vue?vue&type=template&id=480a9277&scoped=true&"
import script from "./switch-role.vue?vue&type=script&lang=js&"
export * from "./switch-role.vue?vue&type=script&lang=js&"
import style0 from "./switch-role.vue?vue&type=style&index=0&id=480a9277&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480a9277",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spacer: require('/data/service/jenkins/workspace/cce/diku-position/src/components/spacer.vue').default})
