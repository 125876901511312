export enum UserLockFlag {
  LOCK = '1',
  UNLOCK = '0',
}

export enum UserAccessChannel {
  MIDI_DUCK = 1,
  OSPREY_APP = 2,
  ENGINEER = 3,
}

export interface UserInfo {
  accessChannel: UserAccessChannel
  appleId: string
  avatar: string
  createTime: string
  miniOpenid: string
  delFlag: string
  deptId: number
  lockFlag: UserLockFlag
  phone: string
  qqOpenid: string
  roleList: any[]
  salt: string
  tenantId: number
  updateTime: string
  userId: number
  username: string
  wxOpenid: string
  role?: Array<number | string>
  password?: string
}
