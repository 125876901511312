//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SwitchRole',
  props: {
    roleList: {
      type: Array,
      default() {
        return []
      },
    },
    current: {
      type: [String, Number],
    },
  },
  computed: {
    isDisabled() {
      return (role) => {
        return `${this.current}` === `${role.roleId}`
      }
    },
  },
  methods: {
    switchRole(role) {
      if (!this.isDisabled(role)) {
        this.$emit('switchRole', role)
      }
    },
  },
}
